.styled-bullets ol {
    counter-reset: item;
    margin-bottom: 16px;
    li {
        display: block;
        margin-left: 30px;
        margin-top: 8px;
        margin-bottom: 8px;
        list-style-type: none;
        position: relative;
        ol li:before {
            left: -50px;
            width: 50px;
        }
        ol li {
            //list in list
            margin-left: 50px;
            ol li:before {
                left: -70px;
                width: 70px;
            }
            ol li {
                //list in list
                margin-left: 70px;
            }
        }
    }
    li:before {
        position: absolute;
        left: -30px;
        width: 30px;
        content: counters(item, ".") ") ";
        counter-increment: item;
    }
}

.styled-bullets ul {
    li {
        margin-left: 24px;
    }
}
