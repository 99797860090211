$primary: #38939b;

$primary_lighter: lighten($primary, 40%);
$primary_light: lighten($primary, 20%);
$primary_dark: darken($primary, 20%);
$primary_darker: darken($primary, 40%);

$primary_8: #{$primary}14;

:root {
    --primary_lighter: #{$primary_lighter};
    --primary_light: #{$primary_light};
    --primary: #{$primary};
    --primary_dark: #{$primary_dark};
    --primary_darker: #{$primary_darker};

    --primary_8: #{$primary_8};
}

:export {
    primary_lighter: $primary_lighter;
    primary_light: $primary_light;
    primary: $primary;
    primary_dark: $primary_dark;
    primary_darker: $primary_darker;

    primary_8: $primary_8;
}
