$grey000: #ffffff;
$grey100: #f9fafb;
$grey200: #f4f6f8;
$grey300: #dfe3e8;
$grey400: #c4cdd5;
$grey500: #919eab;
$grey600: #637381;
$grey700: #454f5b;
$grey800: #212b36;
$grey900: #161c24;

$grey500_8: #{$grey500}14;
$grey500_12: #{$grey500}1f;
$grey500_16: #{$grey500}29;
$grey500_24: #{$grey500}3d;
$grey500_32: #{$grey500}52;
$grey500_48: #{$grey500}7a;
$grey500_56: #{$grey500}8f;
$grey500_80: #{$grey500}cc;

:root {
    --grey000: #{$grey000};
    --grey100: #{$grey100};
    --grey200: #{$grey200};
    --grey300: #{$grey300};
    --grey400: #{$grey400};
    --grey500: #{$grey500};
    --grey600: #{$grey600};
    --grey700: #{$grey700};
    --grey800: #{$grey800};
    --grey900: #{$grey900};

    --grey500_8: #{$grey500_8};
    --grey500_12: #{$grey500_12};
    --grey500_16: #{$grey500_16};
    --grey500_24: #{$grey500_24};
    --grey500_32: #{$grey500_32};
    --grey500_48: #{$grey500_48};
    --grey500_56: #{$grey500_56};
    --grey500_80: #{$grey500_80};
}

:export {
    grey000: $grey000;
    grey100: $grey100;
    grey200: $grey200;
    grey300: $grey300;
    grey400: $grey400;
    grey500: $grey500;
    grey600: $grey600;
    grey700: $grey700;
    grey800: $grey800;
    grey900: $grey900;

    grey500_8: $grey500_8;
    grey500_12: $grey500_12;
    grey500_16: $grey500_16;
    grey500_24: $grey500_24;
    grey500_32: $grey500_32;
    grey500_48: $grey500_48;
    grey500_56: $grey500_56;
    grey500_80: $grey500_80;
}
