.legacy-technical-update {
    p {
        padding-bottom: 12px;
        margin-left: 24px;
    }
    strong {
        display: block;
        padding-bottom: 12px;
        padding-top: 8px;
    }
    ul {
        margin-left: 48px;
    }
}

.force-urbanist {
    * {
        font-family: Urbanist, sans-serif !important;
    }
}
